html,
body {
    height: 100%;
    background-image: url('images/secure_mercatis_lock-orange.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.main {
    background: rgba(255, 255, 255, 0.5);
}

.OTP {
    font-size: 48px;
}

h1 {
    color: darkslategray;
}

h3 {
    color: lightsalmon;
}

.fileName {
    color: rgb(104, 63, 14);
}

.dropzone {
    border: 1px dashed slategray;
    background-color: rgba(180, 180, 180, 0.8);
    padding: 20px;
    margin: 5px 0px 15px 20px;
}

.dropzone-progress {
    border: 1px solid rgb(39, 90, 148);
    background-color: rgba(180, 180, 180, 0.8);
    padding: 20px;
    margin: 5px 0px 15px 20px;
}

.dropzone-done {
    border: 1px solid lightsalmon;
    background-color: rgba(180, 180, 180, 0.8);
    padding: 20px;
    margin: 5px 0px 15px 20px;
}

.dropzone:hover {
    border: 1px dotted black;
    background-color: rgba(255, 160, 120, 0.8);
    padding: 20px;
    margin: 5px 0px 15px 20px;
}